export const BetLimit = {
  dg: {
    max: 1,
    options: [
      {
        text: '10-2,500',
        value: 'L',
      },
      {
        text: '10-20,000',
        value: 'P',
      },
      {
        text: '50-1,500',
        value: 'J',
      },
      {
        text: '50-3,000',
        value: 'K',
      },
      {
        text: '50-5,000',
        value: 'B',
      },
      {
        text: '50-10,000',
        value: 'C',
      },
      {
        text: '50-80,000',
        value: 'N',
      },
      {
        text: '100-10,000',
        value: 'D',
      },
      {
        text: '100-20,000',
        value: 'E',
      },
      {
        text: '100-50,000',
        value: 'F',
      },
      {
        text: '100-100,000',
        value: 'G',
      },
      {
        text: '100-150,000',
        value: 'O',
      },
      {
        text: '100-250,000',
        value: 'A',
      },
      {
        text: '500-500,000',
        value: 'H',
      },
      {
        text: '1,000-100,000',
        value: 'Q',
      },
      {
        text: '10,000-1,000,000',
        value: 'I',
      },
    ],
  },
  prettygaming: {
    max: 6,
    options: [
      {
        text: '5-2,000',
        value: 1001,
      },
      {
        text: '10-400',
        value: 1002,
      },
      {
        text: '10-5,000',
        value: 1019,
      },
      {
        text: '10-20,000',
        value: 1021,
      },
      {
        text: '20-800',
        value: 1003,
      },
      {
        text: '50-2,000',
        value: 1004,
      },
      {
        text: '50-5,000',
        value: 1014,
      },
      {
        text: '50-10,000',
        value: 1020,
      },
      {
        text: '100-4,000',
        value: 1005,
      },
      {
        text: '200-8,000',
        value: 1006,
      },
      {
        text: '100-20,000',
        value: 1015,
      },
      {
        text: '500-20,000',
        value: 1007,
      },
      {
        text: '500-50,000',
        value: 1016,
      },
      {
        text: '1,000-40,000',
        value: 1008,
      },
      {
        text: '1,000-100,000',
        value: 1017,
      },
      {
        text: '2,000-80,000',
        value: 1009,
      },
      {
        text: '3,000-120,000',
        value: 1010,
      },
      {
        text: '4,000-160,000',
        value: 1011,
      },
      {
        text: '5,000-200,000',
        value: 1012,
      },
      {
        text: '6,000-250,000',
        value: 1013,
      },
      {
        text: '20,000-200,000',
        value: 1018,
      },
    ],
  },
  sa: {
    max: 6,
    options: [
      {
        text: '5-500',
        value: 1,
      },
      {
        text: '5-3,000',
        value: 2199023255552,
      },
      {
        text: '30-3,000',
        value: 2,
      },
      {
        text: '50-5,000',
        value: 128,
      },
      {
        text: '100-5,000',
        value: 4,
      },
      {
        text: '100-10,000',
        value: 2048,
      },
      {
        text: '100-20,000',
        value: 8192,
      },
      {
        text: '300-30,000',
        value: 524288,
      },
      {
        text: '500-50,000',
        value: 1048576,
      },
      {
        text: '1,000-100,000',
        value: 4194304,
      },
      {
        text: '2,000-200,000',
        value: 8388608,
      },
      {
        text: '1,000-350,000',
        value: 549755813888,
      },
      {
        text: '2,500-500,000',
        value: 1099511627776,
      },
    ],
  },
  sexygame: {
    max: 6,
    options: [
      {
        text: '10-1,000',
        value: 150901
      },
      {
        text: '20-5,000',
        value: 150904
      },
      {
        text: '50-3,000',
        value: 150907
      },
      {
        text: '50-5,000',
        value: 150908
      },
      {
        text: '20-10,000',
        value: 150905
      },
      {
        text: '100-20,000',
        value: 150914
      },
      {
        text: '200-50,000',
        value: 150917
      },
      {
        text: '500-100,000',
        value: 150919
      },
      {
        text: '2,500-200,000',
        value: 150925
      },
      {
        text: '5,000-200,000',
        value: 150928
      },
      {
        text: '5,000-500,000',
        value: 150929
      },
    ],
  },
  wm: {
    max: 1,
    options: [
      {
        text: '5-500',
        value: 59,
      },
      {
        text: '10-3,000',
        value: 200,
      },
      {
        text: '50-5,000',
        value: 3,
      },
      {
        text: '100-10,000',
        value: 4,
      },
      {
        text: '200-20,000',
        value: 21,
      },
      {
        text: '300-30,000',
        value: 22,
      },
      {
        text: '500-50,000',
        value: 30,
      },
      {
        text: '1,000-100,000',
        value: 31,
      },
      {
        text: '2,000-200,000',
        value: 32,
      },
    ],
  },
  xg: {
    max: 6,
    options: [
      {
        text: '20-1,000',
        value: 101,
      },
      {
        text: '40-2,000',
        value: 102,
      },
      {
        text: '100-5,000',
        value: 103,
      },
      {
        text: '50-10,000',
        value: 104,
      },
      {
        text: '50-20,000',
        value: 105,
      },
      {
        text: '100-30,000',
        value: 106,
      },
      {
        text: '200-50,000',
        value: 107,
      },
      {
        text: '200-100,000',
        value: 108,
      },
      {
        text: '500-200,000',
        value: 109,
      },
      {
        text: '1,000-400,000',
        value: 110,
      },
    ],
  },
  ag: {
    max: 1,
    options: [
      {
        text: '20-50,000',
        value: 'A',
      },
      {
        text: '50-5,000',
        value: 'B',
      },
      {
        text: '20-10,000',
        value: 'C',
      },
      {
        text: '200-20,000',
        value: 'D',
      },
      {
        text: '300-30,000',
        value: 'E',
      },
      {
        text: '400-40,000',
        value: 'F',
      },
      {
        text: '500-50,000',
        value: 'G',
      },
      {
        text: '1,000-100,000',
        value: 'H',
      },
      {
        text: '2,000-200,000',
        value: 'I',
      },
    ],
  },
  allbet: {
    max: 3,
    options: [
      {
        text: '10-1,000',
        value: 'K3',
      },
      {
        text: '20-10,000',
        value: 'A',
      },
      {
        text: '50-25,000',
        value: 'N10',
      },
      {
        text: '100-50,000',
        value: 'A2',
      },
      {
        text: '200-100,000',
        value: 'A3',
      },
      {
        text: '500-125,000',
        value: 'MB16',
      },
      {
        text: '1,000-150,000',
        value: 'MB19',
      },
    ],
  },
  cherry: {
    max: 1,
    options: [
      {
        text: '5-500',
        value: 1,
      },
      {
        text: '30-3,000',
        value: 2,
      },
      {
        text: '10-1,000',
        value: 16,
      },
      {
        text: '20-1,000',
        value: 32,
      },
      {
        text: '50-1,500',
        value: 64,
      },
      {
        text: '50-5,000',
        value: 128,
      },
      {
        text: '100-2,000',
        value: 256,
      },
      {
        text: '500-5,000',
        value: 1024,
      },
      {
        text: '100-10,000',
        value: 2048,
      },
      {
        text: '100-20,000',
        value: 8192,
      },
      {
        text: '500-20,000',
        value: 32768,
      },
      {
        text: '1,000-50,000',
        value: 262144,
      },
      {
        text: '500-50,000',
        value: 1048576,
      },
      {
        text: '1,000-100,000',
        value: 4194304,
      },
      {
        text: '2,000-200,000',
        value: 8388608,
      },
      {
        text: '10,000-200,000',
        value: 33554432,
      },
      {
        text: '2,000-80,000',
        value: 1073741824,
      },
      {
        text: '200-50,0000',
        value: 8589934592,
      },
      {
        text: '500-25,000',
        value: 68719476736,
      },
      {
        text: '100-50,000',
        value: 137438953472,
      },
      {
        text: '100-100,000',
        value: 274877906944,
      },
      {
        text: '100-250,000',
        value: 549755813888,
      },
    ],
  },
  gclub: {
    max: 3,
    options: [
      {
        text: '5-500',
        value: 1,
      },
      {
        text: '20-1,000',
        value: 2,
      },
      {
        text: '50-2,000',
        value: 3,
      },
      {
        text: '100-5,000',
        value: 4,
      },
      {
        text: '200-10,000',
        value: 5,
      },
      {
        text: '500-30,000',
        value: 6,
      },
      {
        text: '1,000-50,000',
        value: 7,
      },
      {
        text: '2,000-100,000',
        value: 8,
      },
      {
        text: '5,000-200,000',
        value: 9,
      },
      {
        text: '10,000-500,000',
        value: 10,
      },
      {
        text: '1-30,000',
        value: 11,
      },
      {
        text: '100-20,000',
        value: 12,
      },
      {
        text: '1,000-3,000,000',
        value: 13,
      },
      {
        text: '5,000-5,000,000',
        value: 14,
      },
      {
        text: '10,000-10,000,000',
        value: 15,
      },
      {
        text: '100-50,000',
        value: 16,
      },
      {
        text: '1,000-30,000,000',
        value: 17,
      },
    ]
  }
}
