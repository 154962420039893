<template>
  <div v-if="isSettingData" class="text-center py-4">
    <b-spinner v-if="isSettingData" size="lg" />
  </div>
  <div v-else>
    <ValidationObserver
        ref="agentForm"
        v-slot="{ handleSubmit, failed }"
    >
      <b-toast
          :visible="failed"
          title="Warning"
          variant="warning"
      >
        {{ $t('messages.invalid_form') }}
      </b-toast>
      <b-row
          v-if="agentId"
          class="pt-2"
      >
        <b-col>
          <small class="mb-1">
            <i class="uil-calender"></i>
            {{ $t('user.registered_date') }}
          </small>
          <p class="text-muted">
            {{ agentDetail.createdAt | datetime }}
          </p>
        </b-col>
        <b-col>
          <small class="mb-1">
            <i class="uil-calender"></i>
            อัพเดทล่าสุด
          </small>
          <p class="text-muted">
            {{ agentDetail.updatedAt | datetime }}
          </p>
        </b-col>
        <b-col>
          <small class="mb-1">
            <i class="uil-shield"></i>
            Role
          </small>
          <p class="text-muted">
            {{ agentDetail.agentType }}
          </p>
        </b-col>
        <b-col>
          <small class="mb-1">
            <i class="uil-shield"></i>
            Credit
          </small>
          <p class="text-muted">
            {{ agentDetail.credit | currency }}
          </p>
        </b-col>
      </b-row>
      <b-overlay :show="isUpdating">
        <form
          autocomplete="off"
          @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-tabs pills vertical>
          <b-tab title="ข้อมูลทั่วไป" active>
            <AgentSettingGeneral :is-update="isUpdate" :agent-data="agentData" @update="updateAgentData" />
          </b-tab>
          <b-tab title="ตั้งค่าเว็บไซต์">
            <AgentSettingWebsite :is-update="isUpdate" :agent-data="agentData" @update="updateAgentData" />
          </b-tab>
          <b-tab title="ตั้งค่าล็อคอินผ่านไลน์">
            <AgentSettingLineLogin :agent-data="agentData" @update="updateAgentData" />
          </b-tab>
          <b-tab :title="$t('contact.title')">
            <AgentSettingContact :agent-data="agentData" @update="updateAgentData" />
          </b-tab>
          <b-tab title="ตั้งค่าการถอนเงิน">
            <AgentSettingWithdraw :agent-data="agentData" @update="updateAgentData" />
          </b-tab>
          <b-tab title="ตั้งค่าระบบเงินคืน">
            <AgentSettingCashback :agent-data="agentData" @update="updateAgentData" />
          </b-tab>
          <b-tab title="ตั้งค่าระบบแนะนำ">
            <AgentSettingAffiliate :agent-data="agentData" @update="updateAgentData" />
          </b-tab>
          <b-tab title="ตั้งค่าเกม">
            <AgentSettingGame :game-data="gamesData" @update="updateGameDate" />
          </b-tab>
          <b-tab title="ตั้งค่าสมัครสมาชิก">
            <AgentSettingRegister :agent-data="agentData" @update="updateAgentData"/>
          </b-tab>
          <b-tab title="ตั้งค่าโปรโมชั่น">
            <AgentSettingPromotion :agent-data="agentData" @update="updateAgentData"/>
          </b-tab>
        </b-tabs>

        <div class="text-right">
          <b-button
              class="mr-2"
              to="/agents/list"
              variant="light"
          >
            {{ $t('buttons.back') }}
          </b-button>
          <b-overlay
              :show="isLoading"
              rounded
              spinner-small
              class="d-inline-block"
          >
            <b-button
                type="submit"
                variant="primary"
                block
            >
              {{ $t('buttons.save') }}
            </b-button>
          </b-overlay>
        </div>
      </form>
      </b-overlay>
    </ValidationObserver>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {BetLimit} from '@src/views/main/players/bet-limit'

export default {
  components: {
    AgentSettingGeneral: () => import('@components/agent-setting/general'),
    AgentSettingLineLogin: () => import('@components/agent-setting/line-login'),
    AgentSettingContact: () => import('@components/agent-setting/contact'),
    AgentSettingWebsite: () => import('@components/agent-setting/website'),
    AgentSettingWithdraw: () => import('@components/agent-setting/withdraw'),
    AgentSettingCashback: () => import('@components/agent-setting/cashback'),
    AgentSettingAffiliate: () => import('@components/agent-setting/affiliate'),
    AgentSettingGame: () => import('@components/agent-setting/game'),
    AgentSettingRegister: () => import('@components/agent-setting/register'),
    AgentSettingPromotion:() => import('@components/agent-setting/promotion.vue')
  },
  props: {
    agentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isUpdating: false,
      isShowPassword: false,
      isSettingData: true,
      agentData: {
        prefix: '',
        name: '',
        registrationUrl: '',
        minWithdrawCredit: null,
        maxAutoWithdraw: 0,
        maxWithdrawCreditTimePerDay: null,
        minWithdrawAffiliate: null,
        betAffiliateImage: null,
        isEnableDepositAffiliate: false,
        minDepositAffiliate: null,
        affiliateCommission: null,
        affiliateCommissionType: '0',
        maxWithdrawAffiliateTimePerDay: null,
        minDepositForEnableAffiliate: null,
        isEnableBetAffiliate: false,
        selected: 0,
        turnTypeOptions: [
          { text: 'เทิร์นเครดิต', value: 0 },
          { text: 'เทิร์นโอเวอร์', value: 1 },
          { text: 'ไม่มีเทิร์น', value: 2 },
        ],
        depositAffiliateTurnRate: 0,
        haveMaxWithdrawDepositAffiliate: false,
        maxWithdrawDepositAffiliate: 0,
        maxWithdrawTypeDepositAffiliate: 0,
        depositAffiliateImage: null,
        cashbackCommissionRate: null,
        cashbackQuotaPerDay: null,
        minWithdrawCashback: null,
        maxWithdrawCashbackTimePerDay: null,
        lineChannelId: '',
        lineChannelSecret: '',
        lineId: '',
        lineQrCode: null,
        contact: '',
        isEnableLineAuth: false,
        affiliateLevelSettings: [],
        agentGameConfigs: [],
        username: '',
        password: '',
        phoneNumber: '',
        credit: null,
        logo: null,
        favicon: null,
        metaKeyword: [],
        isEnableCashbackTime: false,
        cashbackTime: {
          sunday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          monday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          tuesday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          wednesday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          thursday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          friday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          saturday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
        },
        isSkipVerification: false,
        limitWithdrawBetTxn: 0,
        allowPromotionTypeList: [],
      },
      gamesData: [],
      gameType: {
        ALL: 0,
        SLOT: 1,
        FISHING: 2,
        CASINO: 3,
        SPORT: 4,
        GRAPH: 5,
      }
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) =>
        state.agent.isCreatingAgent || state.agent.isUpdatingAgent,
    }),
    ...mapGetters([
      'isAgent',
      'agentDetail',
      'isOwner',
      'isMaster',
      'userInfo',
    ]),
    ...mapGetters({
      games: 'miniGames',
    }),
    daysInWeek() {
      return [
        { en: 'sunday', th: 'วันอาทิตย์' },
        { en: 'monday', th: 'วันจันทร์' },
        { en: 'tuesday', th: 'วันอังคาร' },
        { en: 'wednesday', th: 'วันพุธ' },
        { en: 'thursday', th: 'วันพฤหัสบดี' },
        { en: 'friday', th: 'วันศุกร์' },
        { en: 'saturday', th: 'วันเสาร์' },
      ]
    },
    isUpdate() {
      return this.agentId !== ''
    }
  },
  watch: {
    games(val) {
      if (val.length > 0) {
        this.setGameData()
      }
    },
  },
  async created() {
    await this.fetchGames()
    if (this.agentId) {
      await this.fetchAgent(this.agentId)
    }
    await this.setData()
  },
  methods: {
    ...mapActions(['fetchAgent', 'fetchGames', 'createAgent', 'updateAgent']),
    setData() {
      this.isSettingData = true
      const isUpdatePage = this.agentId && this.agentDetail
      if (isUpdatePage) {
        // parse all values
        const data = JSON.parse(JSON.stringify(this.agentDetail))

        this.agentData = {
          ...this.agentData,
          ...data,
          metaKeyword: data.metaKeyword ? data.metaKeyword.split(',') : [],
          cashbackTime: {
            sunday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
            monday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
            tuesday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
            wednesday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
            thursday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
            friday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
            saturday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
            ...data.cashbackTime,
          },
        }

        // has game config
        if (data.agentGameConfigs && data.agentGameConfigs.length > 0) {
          this.setGameData(data.agentGameConfigs)
        }
      } else {
        this.agentData = {
          prefix: '',
          name: '',
          registrationUrl: '',
          minWithdrawCredit: 0,
          maxAutoWithdraw: 0,
          maxWithdrawCreditTimePerDay: 0,
          minWithdrawAffiliate: 0,
          betAffiliateImage: null,
          maxWithdrawAffiliateTimePerDay: 1,
          minDepositForEnableAffiliate: 0,
          isEnableDepositAffiliate: false,
          isEnableBetAffiliate: false,
          depositAffiliateTurnRate: 0,
          haveMaxWithdrawDepositAffiliate: false,
          maxWithdrawDepositAffiliate: 0,
          maxWithdrawTypeDepositAffiliate: 0,
          depositAffiliateImage: null,
          cashbackCommissionRate: 0,
          cashbackQuotaPerDay: 0,
          minWithdrawCashback: 0,
          maxWithdrawCashbackTimePerDay: 0,
          lineChannelId: '',
          lineChannelSecret: '',
          lineId: '',
          lineQrCode: null,
          contact: '',
          isEnableLineAuth: false,
          affiliateLevelSettings: [],
          agentGameConfigs: [],
          username: '',
          password: '',
          phoneNumber: '',
          credit: 0,
          metaKeyword: [],
          isEnableCashbackTime: false,
          cashbackTime: {
            sunday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
            monday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
            tuesday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
            wednesday: {
              isEnable: false,
              startTime: '00:00',
              endTime: '24:00',
            },
            thursday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
            friday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
            saturday: { isEnable: false, startTime: '00:00', endTime: '24:00' },
          },
          isSkipVerification: false,
          allowPromotionTypeList: [],
          limitWithdrawBetTxn: 0,
          selected: 0,
          turnTypeOptions: [
            { text: 'เทิร์นเครดิต', value: 0 },
            { text: 'เทิร์นโอเวอร์', value: 1 },
            { text: 'ไม่มีเทิร์น', value: 2 },
          ],
        }
      }
      this.isSettingData = false
    },
    setGameData(gameConfigs) {
      if (this.games && this.games.length > 0) {
        this.gamesData = []
        let gameData = {}

        this.games.forEach((game) => {
          let found = false
          if(gameConfigs && gameConfigs.length > 0) {
            found = gameConfigs.find(
                (config) => config.gameConfigId === game.id
            )
          }

          if (found) {
            const providerKey = found.providerKey ? JSON.parse(found.providerKey) : {}
            const commissionRate = found && found.commissionRate ? found.commissionRate : game.commissionRate
            const hasGameKey = Object.keys(providerKey).length > 0
            providerKey['hasGameKey'] = hasGameKey
            gameData = {
              ...game,
              gameConfigId: game.id,
              isSelect: !!found,
              providerKey: {
                ...providerKey,
                hasGameKey
              },
              commissionRate: commissionRate,
              status: found?.status || game.status,
              masterCommissionRate: game.commissionRate,
            }
          } else {
            const providerKey = game.providerKey ? JSON.parse(game.providerKey) : {}
            gameData = {
              ...game,
              providerKey: {
                ...providerKey,
                hasGameKey: false
              },
              gameConfigId: game.id,
              status: 0,
              isSelect: false,
            }
          }

          // has bet limit config
          if (gameData.providerKey['GAME_BET_LIMIT']) {
            const betLimitConfig =
                gameData.providerKey['GAME_BET_LIMIT'].length
                ? JSON.parse(gameData.providerKey['GAME_BET_LIMIT'])
                : []
            gameData.providerKey['GAME_BET_LIMIT'] = this.mapValueToBetOption(
              betLimitConfig,
              gameData.rawName
            )
          }

          this.gamesData.push(gameData)
        })
      }
    },
    mapValueToBetOption(valueArr, gamePath) {
      const betLimitOptions = BetLimit[gamePath].options
      const ret = valueArr.map((v) => {
        return betLimitOptions?.find((option) => v === option.value)
      })
      return ret?.filter((v) => !!v)
    },
    updateAgentData(value) {
      this.agentData = {
        ...this.agentData,
        ...value
      }
    },
    updateGameDate(value) {
      this.gamesData = value
    },
    async onSubmit() {
      this.isUpdating = true
      if (this.gamesData.length > 0) {
        const agentGameConfigs = this.gamesData.filter(
          (game) => game.isSelect === true
        )
        this.agentData.agentGameConfigs = agentGameConfigs.map((game) => {
          let providerKey = ''
          if(game.providerKey.hasGameKey && Object.keys(game.providerKey).length) {
            providerKey = { ...game.providerKey }
            delete providerKey.hasGameKey
            if (Array.isArray(providerKey['GAME_BET_LIMIT'])) {
              providerKey['GAME_BET_LIMIT'] = JSON.stringify(
                providerKey['GAME_BET_LIMIT'].map((bl) => bl.value)
              )
            }
            providerKey = JSON.stringify(providerKey)
            
          }
          return {
            gameConfigId: game.gameConfigId,
            commissionRate: game.commissionRate,
            status: game.status,
            providerKey: providerKey,
          }
        })
      }
      this.agentData.minWithdrawCredit = +this.agentData.minWithdrawCredit
      this.agentData.maxWithdrawCreditTimePerDay = +this.agentData.maxWithdrawCreditTimePerDay
      this.agentData.minWithdrawAffiliate = +this.agentData.minWithdrawAffiliate
      this.agentData.maxWithdrawAffiliateTimePerDay = +this.agentData.maxWithdrawAffiliateTimePerDay
      this.agentData.cashbackCommissionRate = +this.agentData.cashbackCommissionRate
      this.agentData.cashbackQuotaPerDay = +this.agentData.cashbackQuotaPerDay
      this.agentData.minWithdrawCashback = +this.agentData.minWithdrawCashback
      this.agentData.maxWithdrawCashbackTimePerDay = +this.agentData.maxWithdrawCashbackTimePerDay
      this.agentData.credit = +this.agentData.credit

      const affiliateLevelSettings = this.agentData.affiliateLevelSettings.map((affiliateLevelSetting, index) => {
        return {
          level: index + 1,
          commissionRate: affiliateLevelSetting.commissionRate
        }
      })

      if (this.agentId) {
        await this.updateAgent({
          id: this.agentId,
          data: {
            ...this.agentData,
            affiliateLevelSettings,
            metaKeyword: this.agentData.metaKeyword.join()
          }
        })
      } else {
        await this.createAgent({
          ...this.agentData,
          affiliateLevelSettings
        })
      }
      this.isUpdating = false
    },
  },
}
</script>

<style>
.tab-content {
  padding-top: 0;
}
</style>